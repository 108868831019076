:root {
  --main-color: #ff5b36;
  --heading-color: #222;
  --body-color: #666;
  --body-extra-color: #777;
  --body-background-color: #eeeeee;
}

body {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.3333;
  font-weight: 400;
  color: var(--body-color);
  position: relative;
  word-break: break-word;
  background-color: var(--body-background-color);
  overflow-x: hidden;
}
