.search-menu {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;

  margin: 0;
  outline: none;
  position: absolute;
  max-width: 100%;
  min-width: 100%;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 24px 0px 22px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
  /* left: -30px; */
  /* opacity: 0; */
  /* visibility: hidden; */
  transform: translateY(20px);
  transition: all 0.3s ease;
  top: 100%;
  list-style-type: none;
  /* left: -150px; */
  z-index: 100;
}
.search-menu ul {
  left: 100%;
  top: -24px;
}
.search-menu li {
  margin-bottom: 9px;
  position: relative;
  padding: 0 30px;
}
.search-menu li:last-child {
  margin-bottom: 0;
}
.search-menu a {
  display: block;
  line-height: 1.5;
  color: #222;
  text-decoration: none;
  font-weight: 500;
}
.search-menu a:hover {
  color: #ff5b36;
}
.search-menu img {
  margin-right: 3%;
}
