.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 45vh;
  text-align: center;
}

h1 {
  font-size: 8rem;
  font-weight: bold;
  margin-bottom: 0;
  color: #333;
}

h2 {
  font-size: 3rem;
  margin-top: 0;
  color: #333;
}

p {
  font-size: 1.5rem;
  color: #333;
  margin: 2rem 0;
}

@media (max-width: 768px) {
  h1 {
    font-size: 6rem;
  }
  h2 {
    font-size: 2rem;
  }
  p {
    font-size: 1.2rem;
    margin: 1rem 0;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  p {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
}
