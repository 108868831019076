* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.switch-button {
  background: rgba(255, 255, 255, 0.56);
  border-radius: 30px;
  overflow: hidden;
  width: 80%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: bold;
  position: relative;
  padding-right: 120px;
  position: relative;

  &:before {
    content: 'Usados';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 51%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(85%);
      transition: transform 300ms linear;
    }

    & + .switch-button-label {
      position: relative;
      padding: 8px 0;
      display: block;
      user-select: none;
      pointer-events: none;
      width: 80%;

      &:before {
        content: '';
        background: #ff8743;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 30px;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}
