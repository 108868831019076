/*
This file is made by Frenify
This css file includes all base styles and template's main color (in this tempalte main color is pink(#f00a77)). 
Template's main color styles located at the bottom of this file.
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

blockquote {
  quotes: none;
}

blockquote:before,
blockquote:after {
  content: '';
  content: none;
}
ul,
ol {
  margin: 30px;
}
ul li,
ol li {
  margin-bottom: 5px;
}
img {
  vertical-align: middle;
  max-width: 100%;
}
iframe {
  max-width: 100%;
}
del {
  text-decoration: line-through;
}
a,
i {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: none;
}
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 20px;
}
h1 {
  font-size: 45px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}

table {
  width: 100%;
  max-width: 100%;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0px;
  margin-bottom: 15px;
  color: #999;
  border: 1px solid #eee;
}
table tr {
  border: 1px solid #eee;
}
table th {
  vertical-align: top;
  color: #333;
  background: none;
  font-size: 16px;
  font-weight: normal;
  padding: 15px 10px;
  border: 1px solid #eee;
}
table td {
  vertical-align: top;
  padding: 10px;
  border: 1px solid #eee;
}

code,
pre {
  padding: 10px;
  padding-bottom: 0;
  border-left: 3px solid #6fbf71;
  border-bottom: 1px solid transparent;
  background: #f7f7f7;
  overflow-x: scroll;
}

pre {
  display: block;
  word-break: break-all;
  word-wrap: break-word;
}
pre code {
  white-space: pre-wrap;
}

.space10 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 10px;
}
.space20 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 20px;
}
.space30 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 30px;
}
.space40 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 40px;
}
.space50 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 50px;
}
.space60 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 60px;
}
.space70 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 70px;
}
.space80 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 80px;
}
.space90 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 90px;
}
.space100 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 100px;
}
.space200 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 200px;
}
.space300 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 300px;
}
.space400 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 400px;
}
.space500 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 500px;
}
.space1000 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 1000px;
}

/*------------------------------------------------------------------*/
/*	x) FORM ELEMENTS
/*------------------------------------------------------------------*/
textarea {
  border-radius: 0;
  font-size: 13px;
  color: #333;
  resize: vertical;
  font-family: 'Raleway', Arial, Helvetica, sans-serif;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  -ms-box-sizing: border-box; /* IE 10 + */
  box-sizing: border-box; /* Opera/IE 8+ */

  line-height: 1.4;
  padding: 10px;
  width: 100%;
  border: 1px solid #eee;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  border-radius: 0;
  font-size: 13px;
  min-width: 220px;
  color: #333;
  letter-spacing: 0.5px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  -ms-box-sizing: border-box; /* IE 10 + */
  box-sizing: border-box; /* Opera/IE 8+ */

  font-family: 'Heebo', Arial, Helvetica, sans-serif;

  line-height: 1.6;
  padding: 10px;
  height: 44px;
  vertical-align: middle;
  border: 1px solid #eee;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
  border-color: #ccc;
  background: #eee;
}

input[type='submit'] {
  padding: 13px 15px;
  height: 40px;
  line-height: 1;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.5px;
  border: none;
  cursor: pointer;
  color: #fff;
  background: #777;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
input[type='submit']:hover {
  background: #444;
}
select {
  padding: 8px;
  border: 2px solid #eee;
}
input[type='button'] {
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
